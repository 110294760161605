// Project Configurations

const Config={
backend:{
endPoint:{
    root:'https://verifyme.com.ng/backend/'
}

}






}


export default Config;