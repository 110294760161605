
import './App.css';
import Index from './App/Components/Index';

function App() {
  return (
   <Index/>
  );
}

export default App;
